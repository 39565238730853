<template>
	<div class="password">
		<div class="password-list">
			<div class="password-list-item">
				<input v-model="editPassword.oldPassword" :type="showOldPwd?'text':'password'" placeholder="请输入旧密码">
				<img @click="isShowVal('oldPwd')" v-if="!showOldPwd" src="@/assets/images/mobile/mine/close-eye.png"
					alt="">
				<img @click="isShowVal('oldPwd')" v-else src="@/assets/images/mobile/mine/open-eye.png" alt="">
			</div>
			<div class="password-list-item">
				<input v-model="editPassword.password" :type="showNewPwd?'text':'password'" placeholder="请输入新密码">
				<img @click="isShowVal('newPwd')" v-if="!showNewPwd" src="@/assets/images/mobile/mine/close-eye.png"
					alt="">
				<img @click="isShowVal('newPwd')" v-else src="@/assets/images/mobile/mine/open-eye.png" alt="">
			</div>
			<div class="password-list-item">
				<input v-model="editPassword.rePassword" :type="showRePwd?'text':'password'" placeholder="请再次输入新密码">
				<img @click="isShowVal('rePwd')" v-if="!showRePwd" src="@/assets/images/mobile/mine/close-eye.png"
					alt="">
				<img @click="isShowVal('rePwd')" v-else src="@/assets/images/mobile/mine/open-eye.png" alt="">
			</div>
			<div class="password-list-item">
				<span>注：密码由6-16位英文字母、数字或符号组合</span>
			</div>
		</div>
		<div @click="submit" class="password-btn password-btn-active">
			确定修改
		</div>
	</div>
</template>

<script>
	import {
		passwordEditMb,
	} from "@/api/mobile/mine"
	export default {
		data() {
			return {
				showOldPwd: false,
				showNewPwd: false,
				showRePwd: false,
				editPassword: {
					oldPassword: "",
					password: "",
					rePassword: ""
				},
			}
		},
		methods: {
			isShowVal(type) {
				switch (type) {
					case "oldPwd":
						this.showOldPwd = !this.showOldPwd
						break;
					case "newPwd":
						this.showNewPwd = !this.showNewPwd
						break;
					case "rePwd":
						this.showRePwd = !this.showRePwd
						break;
				}
			},
			submit() {
				let params = JSON.parse(JSON.stringify(this.editPassword))
				if (!params.oldPassword) {
					this.$toast("请输入旧密码")
					return
				} else {
					if (!params.password) {
						this.$toast("请输入新密码")
						return
					} else {
						if (params.password.length < 6 || params.password.length > 16) {
							this.$toast("请输入6-16位英文字母、数字或符号组合密码")
							return
						} else {
							if (!params.rePassword) {
								this.$toast("请输入确认密码")
								return
							} else {
								if (params.rePassword != params.password) {
									this.$toast("输入的新密码和确认密码密码不一致")
									return
								}
							}
						}
					}
				}
				passwordEditMb(params).then((res) => {
					if (res.code == 0) {
						this.$toast("密码修改成功")
						this.$router.go(-1)
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.password {
		display: flex;
		flex-direction: column;

		.password-list {
			display: flex;
			flex-direction: column;
			background: #FFFFFF;
			padding: 0 0.28rem;
			box-sizing: border-box;
			margin-top: 0.16rem;

			.password-list-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 0.96rem;
				border-bottom: 0.02rem solid #DCDFE6;

				input {
					flex: 1;
					flex: 1;
					border: 0;
					outline: none;
				}

				img {
					width: 0.32rem;
					height: 0.32rem;
				}

				span {
					font-size: 0.24rem;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 0.34rem;
					color: #909399;
				}
			}
		}

		.password-list :last-child {
			border: 0;
		}

		.password-btn {
			width: 6.94rem;
			height: 0.8rem;
			background: rgba(67, 146, 250, 0.4);
			border-radius: 0.08rem;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0.32rem auto 0rem auto;
			font-size: 0.28rem;
			font-family: PingFang SC;
			font-weight: bold;
			color: #FFFFFF;
		}

		.password-btn-active {
			background: #4392FA;
		}
	}
</style>
